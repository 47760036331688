body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Piedra",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
  /* scrollbar-width: thin; */
  /* scrollbar-color: rgba(0, 0, 0, 0.05); */
}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

body::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 0.5rem;
  border: 1px solid rgba(86, 81, 183, 0.5);
}

/* .triangle {
  width: 1920px;
  height: 200px;
  background: red linear-gradient(red, blue);
  clip-path: polygon(0% 100%, 0% 0%, 100% 100%);
} */
